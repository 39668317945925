import {PsObject} from "@/object/core/PsObject";
import {retro} from "vue3-google-map/dist/types/themes";

export default class Bidding extends PsObject<Bidding>{

    id: string = ''
    bid_user_id: string = ''
    bid_price: string = ''
    user_name: string = ''
    created_at: string = ''

    init(
        id: string,
        bid_user_id: string,
        bid_price: string,
        user_name: string,
        created_at: string
    ) {
        this.id = id
        this.bid_price = bid_price
        this.bid_user_id = bid_user_id
        this.user_name = user_name
        this.created_at = created_at

        return this;
    }

    getPrimaryKey(): string {
        return this.id;
    }

    toMap(object: Bidding): any {
        const map = {};

        map["id"] = object.id
        map["bid_price"] = object.bid_price
        map["bid_user_id"] = object.bid_user_id
        map["user_name"] = object.user_name
        map["created_at"] = object.created_at

        return map
    }

    toMapList(objectList: Bidding[]): any[] {
        const mapList: any[] = [];
        for (let i = 0; i < objectList.length; i++) {
            if (objectList[i] != null) {
                mapList.push(this.toMap(objectList[i]));
            }
        }

        return mapList;
    }

    fromMap(obj: any) {
        return new Bidding().init(
            obj.id,
            obj.bid_user_id,
            obj.bid_price,
            obj.user_name,
            obj.created_at
        );
    }

    fromMapList(objList: any[]): Bidding[] {
        const bidding: Bidding[] = [];
        for (const obj of objList as Array<Bidding>) {
            if (obj != null) {
                bidding.push(this.fromMap(obj));
            }
        }

        return bidding;
    }
}