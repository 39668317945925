export default class PsConfig {

    static versionNo : String = '1.3';

    ///
    /// API Key to connection with Main Backend
    /// This key must equal with key from backend
    ///
    static apiKey: String = "teampsisthebest";

    ///
    /// Live Mode
    /// ------------
    /// If you want to upload to server, please enable below and comment Development below
    ///
    /*static fixedDomain: String = "http://3jalcom.test/admotor-admin";
    static domain: String = "http://localhost:3000";*/

    static fixedDomain: String = "https://www.3jlcom.com/admotor-admin";
    static domain: String = "https://www.3jlcom.com/admotor-admin";

    ///
    /// Development Mode
    /// -----------------
    /// Before upload to server, please comment this 2 lines.
    ///
    // static fixedDomain: String = "https://www.3jlcom.com/admotor-admin";    
    // static domain: String = "http://localhost:3000";
    
    static firebaseConfig = {
        apiKey: "AIzaSyAVbVr22_RrSflA83ASIdf6QBasd4l5HMs",
        authDomain: "jlcom-930ba.firebaseapp.com",
        projectId: "jlcom-930ba",
        storageBucket: "jlcom-930ba.appspot.com",
        messagingSenderId: "637339761601",
        appId: "1:637339761601:web:0e99b0596dcab08d4ccd40",
        measurementId: "G-9CDEYPR7JB"
      };
    
    ///
    /// Base URL * Don't update it *
    ///
    static base: String = PsConfig.domain; 
    static baseUrl: String = PsConfig.domain + '/index.php';
    static fixedPath : String = PsConfig.fixedDomain;

    /// 
    /// Debug Mode
    ///
    static isDebugMode : Boolean = false;

    /// Is Demo
    ///
    static is_demo : Boolean = false;

}
